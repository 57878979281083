@font-face {
  font-family: 'Andromeda';
  font-weight: 200;
  src: url('./assets/fonts/rubik/Rubik-Light.woff') format('woff'),
       url('./assets/fonts/rubik/Rubik-Light.woff2')  format('woff2');
}

@font-face {
  font-family: 'Andromeda';
  font-weight: normal;
  src: url('./assets/fonts/rubik/Rubik-Regular.woff') format('woff'),
       url('./assets/fonts/rubik/Rubik-Regular.woff2')  format('woff2');
}

@font-face {
  font-family: 'Andromeda';
  font-weight: bold;
  src: url('./assets/fonts/rubik/Rubik-Medium.woff') format('woff'),
       url('./assets/fonts/rubik/Rubik-Medium.woff2')  format('woff2');
}


iframe{
  display: none !important;
}

body {
	font-family: 'Andromeda', sans-serif !important;
}

.hoverBg:hover{
	background-color: #f8f7fc;
}

.bb-MenuItem:hover{
    background-color: #f1f1f1 !important;
    border-radius: 10px;
}

/* elimina el outline de links */
a, a:focus, button, button:focus{
	outline: none !important;
	box-shadow: none !important;
}

a{
	text-decoration: none !important;
}

.hover-opacity{
    opacity: 0.5;
    transition: all 0.2s ease-out;
}

.hover-opacity:hover{
    opacity: 1;
}

.inAppLogo{
	display: block;
	position: fixed;
	bottom: 20px;
	left: 20px;
	opacity: 0.4;
	transition: all 0.7s cubic-bezier(.19,1,.22,1);
}

.inAppLogo:hover{
	opacity: 0.7;
}

.inAppLogo img{
	width: 60px;
	height: 60px;
}

.listaItem{
	padding-top: 10px;
    
	padding-bottom: 12px;
	color: #000;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 10px;
	
	position: relative;

    background-image: linear-gradient(90deg, #e0dee7, #e0dee7 75%, transparent 75%, transparent 100%);
    background-repeat: repeat-x;
  	background-size: 1000px 1px;
  	background-position: bottom left;
    border: none;
}

.listaItem .asunto{
	color: #56748f;
	font-weight: bold;
}

.listaItem.noLeido .asunto{
	color: #000;
}

.listaItem:hover{
	background-color: #f8f7fc;
}



.listaItem.mensajeAdmin{
	background-color: #fdf5e5;
}

.listaItem.noLeido::before{
    content: '';
    position: absolute;
    left: -10px;
    top: 17px;
    background-color: #6e3ce2;
    width: 7px;
    height: 7px;
    border-radius: 20px;
}

.listaItem.borrador::before{
    content: '';
    position: absolute;
    left: -10px;
    top: 17px;
    background-color: #e6a350;
    width: 7px;
    height: 7px;
    border-radius: 20px;
}

.listaItemFecha{
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 12px;
}

.listaItemClip{
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.pastilla{
	display: inline-block;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	margin-right: 5px;
	padding: 1px 5px;
	font-size: 9px;
	font-weight: bold;
    border-color: rgba(0,0,0,0.3);
    color: rgba(0,0,0,0.5);
}

.attachment{
	color: #435a6f;
	display: inline-block;
	margin-right: 10px;
	border: 1px solid #aaa;
	border-radius: 5px;
	padding: 10px 10px 8px 10px;
	margin-bottom: 10px;
	opacity: 0.5;
	transition: all 0.6s cubic-bezier(.19,1,.22,1);
}

.attachment:hover{
	opacity: 1;
}

.dropdownMainMenu{
	display: none;
}

.columnaMenu{
	margin-right: 14px !important;
	width: 250px;
	flex: 0 1 auto !important;
	min-height: calc(100vh - 115px) !important;
}

.columnaContenido{
	flex: 0 1 auto;
}

.columnaMenu button[role=menuitem]{
	border-radius: 5px;
}

input[type="date"]:before {
    content: attr(placeholder) !important;
    margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

.limpiarBusqueda svg{
    height: 20px;
    width: 20px;
}

.limpiarBusqueda a{
    opacity: 1;
}

.limpiarBusqueda a:hover{
    opacity: 0.7;
}

button.small{
    font-size: 0.9em;
    padding: 10px;
}

button:focus{
    border: 1px solid #d9d9e1 !important;
}

.archivo-editar{
    display: inline-block;
    background-color: #f6f6f9;
    padding: 3px 8px 3px 5px;
    border-radius: 5px;
    position: relative;
    color: #888;
    margin-right: 7px;
}

.archivo-editar-eliminar{
    margin: 5px 0 0 5px;
}


/* removes default input date placeholder */
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}
/* input[type="date"]:in-range::-webkit-datetime-edit-year-field, input[type="date"]:in-range::-webkit-datetime-edit-month-field, input[type="date"]:in-range::-webkit-datetime-edit-day-field, input[type="date"]:in-range::-webkit-datetime-edit-text { 	color: transparent; } */
input[value="0000-00-00"]::-webkit-datetime-edit {
    color: transparent; 
}
input[value="dd/mm/yyyy"]::-webkit-datetime-edit {
    color: transparent; 
}

@media only screen and (max-width: 900px){
    .columnaMenu{
    	display: none;
    }
    .dropdownMainMenu{
    	display: block;
    }
}